var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"align-center":"","shrink":""}},[_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.versions,"no-data-text":"No project history","color":"primary","footer-props.items-per-page-options":"[10, 25, {\"text\": \"$vuetify.dataIterator.rowsPerPageAll\",\"value\": -1}]","hide-default-footer":_vm.versions.length <= 10,"options":_vm.options},scopedSlots:_vm._u([{key:"header.changes.added",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":header.color}},on),[_vm._v(_vm._s(header.icon))])]}}],null,true)},[_c('span',[_vm._v("Added")])])]}},{key:"header.changes.removed",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":header.color}},on),[_vm._v(_vm._s(header.icon))])]}}],null,true)},[_c('span',[_vm._v("Deleted")])])]}},{key:"header.changes.updated",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":header.color}},on),[_vm._v(_vm._s(header.icon))])]}}],null,true)},[_c('span',[_vm._v("Modified")])])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("timediff")(item.created)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.created)))])])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":{name: ((_vm.asAdmin ? 'admin-' : '') + "project-versions-detail"), params: {version_id: item.name, version: item}}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.changes.added",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(item.changes.added.length))])])]}},{key:"item.changes.removed",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.changes.removed.length))])])]}},{key:"item.changes.updated",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticClass:"orange--text"},[_vm._v(_vm._s(item.changes.updated.length))])])]}},{key:"item.project_size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filesize")(item.project_size))+" ")]}},{key:"item.archived",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":'/v1/project/download/' + _vm.namespace + '/'+ _vm.projectName +'?version=' + item.name + '&format=zip'}},on),[_c('v-icon',[_vm._v("archive")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Project Version "+_vm._s(item.name)+" (ZIP)")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }