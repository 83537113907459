var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"table",attrs:{"headers":_vm.header,"items":_vm.transfers,"no-data-text":"No transfers","color":"primary","footer-props.items-per-page-options":"[10, 25, {\"text\": \"$vuetify.dataIterator.rowsPerPageAll\",\"value\": -1}]","hide-default-footer":_vm.transfers.length <= 10,"options":_vm.options},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('router-link',{attrs:{"to":{name: 'project', params: {namespace: item.project.namespace, projectName: item.project.name}}}},[_c('strong',[_vm._v(_vm._s(item.project_name))])])],1),_c('td',[_vm._v(" "+_vm._s(item.from_ns_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.to_ns_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.requested_by)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("remainingtime")(item.expire)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.expire)))])])],1),_c('td',[_c('div',{staticStyle:{"text-align":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[(!_vm.expired(item.expire) && _vm.incoming(item))?_c('v-chip',{staticClass:"white--text",attrs:{"elevation":"0","color":"green"},on:{"click":function($event){return _vm.acceptTransferDialog(item)}}},[_vm._v(" accept ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Accept transfer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-chip',{staticClass:"white--text",attrs:{"elevation":"0","color":"red"},on:{"click":function($event){return _vm.cancelTransfer(item)}}},[_vm._v(" cancel ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancel transfer")])])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }