var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"no-shrink column"},[_c('label',{staticClass:"mt-4 grey--text text--darken-1"},[_vm._v("Access requests:")]),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.project.access_requests,"no-data-text":"No access requests","hide-default-header":"","hide-default-footer":_vm.project.access_requests.length <= 10},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,style:(("width: " + (header.width) + "px"))},[(header.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(header.tooltip)+" ")])]):_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],1)}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[(item.user.profile && (item.user.profile.first_name || item.user.profile.last_name))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('b',_vm._g({},on),[_vm._v(" "+_vm._s(item.user.username)+" ")])]}}],null,true)},[_c('span',[(item.user.profile && item.user.profile.first_name)?_c('span',[_vm._v(_vm._s(item.user.profile.first_name))]):_vm._e(),(item.user.profile && item.user.profile.last_name)?_c('span',[_vm._v(" "+_vm._s(item.user.profile.last_name))]):_vm._e()])]):_c('b',[_vm._v(" "+_vm._s(item.user.username)+" ")])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("remainingtime")(item.expire)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.expire)))])])],1),_c('td',[_c('v-select',{attrs:{"items":['read', 'write', 'owner'],"return-object":""},model:{value:(_vm.permissions[item.id]),callback:function ($$v) {_vm.$set(_vm.permissions, item.id, $$v)},expression:"permissions[item.id]"}})],1),_c('td',{staticClass:"justify-center px-0"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-chip',{staticClass:"white--text",attrs:{"disabled":_vm.expired(item.expire) || (_vm.project.creator === item.user.id || !_vm.project.access.owners.includes(_vm.app.user.id)),"elevation":"0","color":"green"},on:{"click":function($event){return _vm.acceptRequest(item)}}},[_vm._v(" accept ")])],1)]}}],null,true)},[_c('span',[_vm._v("Accept request")])])],1),_c('td',{staticClass:"justify-center "},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-chip',{staticClass:"white--text",attrs:{"disabled":_vm.project.creator === item.user.id || !_vm.project.access.owners.includes(_vm.app.user.id),"elevation":"0","color":"red"},on:{"click":function($event){return _vm.cancelRequest(item)}}},[_vm._v(" cancel ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancel access request")])])],1)])]}}])}),_c('button',{ref:"hidden-btn",staticStyle:{"visibility":"hidden"},attrs:{"id":"accept-request-access-btn"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }