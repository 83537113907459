var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.version)?_c('div',[_c('portal',{attrs:{"to":"download-button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","href":_vm.downloadUrl}},on),[_c('v-icon',[_vm._v("archive")])],1)]}}],null,false,2787266840)},[_c('span',[_vm._v("Download Project Version "+_vm._s(_vm.version.name)+" (ZIP)")])])],1),_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Version")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.version.name))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Author")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.version.author))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Project Size")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("filesize")(_vm.version.project_size)))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Created")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm._f("datetime")(_vm.version.created)))])],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("User Agent")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.version.user_agent))])],1)],1)],1),_c('v-list',{staticClass:"files",attrs:{"expand":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Files changes:")])],1)],1),_vm._l((_vm.changeHeader),function(ref){
var key = ref.key;
var text = ref.text;
var icon = ref.icon;
return _c('v-list-group',{key:key,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},domProps:{"textContent":_vm._s(icon)}}),_c('span',[_vm._v(_vm._s(text)+" ("+_vm._s(_vm.changes[key].length)+")")])],1)],1)]},proxy:true}],null,true)},_vm._l((_vm.changes[key]),function(item){return _c('div',{key:item.path,staticClass:"v-list__tile v-list-item-file",attrs:{"no-action":""}},[_c('div',{class:_vm.colors[key]},[_vm._v(" "+_vm._s(item.path)+": "+_vm._s(_vm._f("filesize")(_vm.version.changesets[item.path] ? _vm.version.changesets[item.path]['size'] : item.size))+" "),(_vm.version.changesets[item.path])?[(!_vm.version.changesets[item.path].error)?_c('div',[_c('router-link',{staticClass:"show-advanced",attrs:{"to":{
                  name: ((_vm.asAdmin ? 'admin-' : '') + "file-version-detail"),
                  params: {
                    namespace: _vm.version.namespace,
                    projectName: _vm.version.project_name,
                    version_id: _vm.version.name,
                    path: item.path }}}},[_c('v-btn',[_c('span',[_vm._v("Show advanced")])])],1),_c('file-changeset-summary-table',{attrs:{"changesets":_vm.version.changesets[item.path]['summary']}})],1):_c('div',{staticClass:"text--primary"},[_vm._v(" Details not available: "+_vm._s(_vm.version.changesets[item.path].error)+" ")])]:_vm._e()],2)])}),0)})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }