var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"no-shrink column"},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.users,"no-data-text":"No users","hide-default-footer":_vm.users.length < 10,"hide-default-header":"","footer-props":{
      itemsPerPageOptions: [10],
    }},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[(header.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(header.tooltip)+" ")])]):_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],1)}),0)])]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[(item.profile.first_name || item.profile.last_name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('b',_vm._g({},on),[_vm._v(" "+_vm._s(item.username)+" ")])]}}],null,true)},[_c('span',[(item.profile.first_name)?_c('span',[_vm._v(_vm._s(item.profile.first_name))]):_vm._e(),(item.profile.last_name)?_c('span',[_vm._v(" "+_vm._s(item.profile.last_name))]):_vm._e()])]):_c('b',[_vm._v(" "+_vm._s(item.username)+" ")])],1),_c('td',[(_vm.editable(item))?_c('v-select',{staticClass:"input-selection",staticStyle:{"width":"120px"},attrs:{"value":item.permission,"items":_vm.permissionStates,"hide-details":"","label":"Select","single-line":""},on:{"input":function (e) { return _vm.valueChanged(item, e); }}}):_c('span',[_vm._v(" "+_vm._s(item.permission)+" ")])],1),_c('td',{staticClass:"justify-center layout px-0"},[_c('v-btn',{attrs:{"disabled":!_vm.editable(item),"icon":""},on:{"click":function($event){return _vm.removeUser(item)}}},[_c('v-icon',{attrs:{"color":"red darken-3"}},[_vm._v("delete")])],1)],1)])]}}])}),_c('br'),(_vm.isAdmin)?_c('div',[_c('h3',[_vm._v("Invitations")]),_c('v-data-table',{attrs:{"headers":_vm.headerInvitations,"items":_vm.invitations,"no-data-text":"No users","hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[(header.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(header.tooltip)+" ")])]):_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],1)}),0)])]}},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[_c('b',[_vm._v(" "+_vm._s(item.username)+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("remainingtime")(item.expire)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.expire)))])])],1),_c('td',{staticStyle:{"text-align":"end"}},[_c('v-chip',{staticClass:"white--text",attrs:{"elevation":"0","color":"red","small":""},on:{"click":function($event){return _vm.removeInvitation(item)}}},[_vm._v(" remove ")])],1)])]}}],null,false,3158327277)}),_c('v-layout',{attrs:{"mx-3":"","my-2":"","align-end":""}},[_c('v-autocomplete',{attrs:{"placeholder":"Find user","loading":_vm.isLoading,"items":_vm.searchResults,"search-input":_vm.userQuery,"clearable":"","return-object":"","no-data-text":"Type more letters","hide-no-data":_vm.userQuery && _vm.userQuery.length > 2,"hide-details":""},on:{"update:searchInput":function($event){_vm.userQuery=$event},"update:search-input":function($event){_vm.userQuery=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('v-list-item-content',{staticStyle:{"padding-bottom":"6px","padding-top":"6px"}},[_c('div',{staticClass:"v-list-item-content",domProps:{"innerHTML":_vm._s(((_vm.emphasizeMatch(item.value.username, _vm.userQuery)) + "  " + (_vm.getUserProfileName(item.value))))}})])]}}],null,false,2360669942),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('v-btn',{staticClass:"primary white--text",attrs:{"disabled":!_vm.user},on:{"click":_vm.createInvitation}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("add_circle")]),_vm._v(" Invite ")],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }