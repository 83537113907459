var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"table",attrs:{"headers":_vm.header,"items":_vm.accessRequests,"no-data-text":"No access requests","color":"primary","footer-props.items-per-page-options":"[10, 25, {\"text\": \"$vuetify.dataIterator.rowsPerPageAll\",\"value\": -1}]","hide-default-footer":_vm.accessRequests.length <= 10,"options":_vm.options},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.user.username)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.project_name)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("remainingtime")(item.expire)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.expire)))])])],1),_c('td',[_c('v-select',{attrs:{"items":['read', 'write', 'owner'],"return-object":"","disabled":item.namespace !== _vm.app.user.username},model:{value:(_vm.permissions[item.id]),callback:function ($$v) {_vm.$set(_vm.permissions, item.id, $$v)},expression:"permissions[item.id]"}})],1),_c('td',{staticClass:"justify-center "},[_c('div',{staticStyle:{"text-align":"end"}},[(item.namespace === _vm.app.user.username)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-chip',{staticClass:"white--text",attrs:{"disabled":_vm.expired(item.expire),"elevation":"0","color":"green","value":_vm.permissions[item.id]},on:{"click":function($event){return _vm.acceptRequest(item)}}},[_vm._v(" accept ")])],1)]}}],null,true)},[_c('span',[_vm._v("Accept request")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-chip',{staticClass:"white--text",attrs:{"elevation":"0","color":"red"},on:{"click":function($event){return _vm.cancelRequest(item)}}},[_vm._v(" cancel ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancel request")])])],1)])])]}}])}),_c('button',{ref:"hidden-btn",staticStyle:{"visibility":"hidden"},attrs:{"id":"accept-request-access-btn"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }