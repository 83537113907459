var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.displayedChangeset,"footer-props.items-per-page-options":"[10, 25, {\"text\": \"$vuetify.dataIterator.rowsPerPageAll\",\"value\": -1}]","hide-default-footer":_vm.displayedChangeset.length <= 10},scopedSlots:_vm._u([{key:"header.insert",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":header.color}},on),[_vm._v(_vm._s(header.icon))])]}}],null,true)},[_c('span',[_vm._v("Added")])])]}},{key:"header.delete",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":header.color}},on),[_vm._v(_vm._s(header.icon))])]}}],null,true)},[_c('span',[_vm._v("Deleted")])])]}},{key:"header.update",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":header.color}},on),[_vm._v(_vm._s(header.icon))])]}}],null,true)},[_c('span',[_vm._v("Modified")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }