var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticStyle:{"margin":"10px"},attrs:{"color":"transparent","outlined":""}},[_c('v-card-text',[_c('v-layout',{staticStyle:{"padding":"5px"},attrs:{"row":""}},[_c('v-radio-group',{staticStyle:{"max-width":"500px","padding-left":"10px"},attrs:{"row":""},on:{"change":_vm.resetPaging},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"label":"User","value":"user"}}),_c('v-radio',{attrs:{"label":"Organisation","value":"organisation"}})],1),_c('v-spacer'),_c('v-text-field',{staticStyle:{"max-width":"250px","padding-right":"10px"},attrs:{"label":_vm.$t('search'),"color":"secondary","hide-details":""},on:{"input":_vm.resetPaging},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndUp)?{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"elevation":"1"}},[_vm._v("mdi-magnify ")])]},proxy:true}:null,{key:"append",fn:function(){return [_c('v-icon',{attrs:{"elevation":"1"},on:{"click":_vm.resetSearch}},[_vm._v("cancel")])]},proxy:true}],null,true),model:{value:(_vm.searchByName),callback:function ($$v) {_vm.searchByName=$$v},expression:"searchByName"}})],1)],1)],1),_c('v-data-table',{attrs:{"height":"70vh","headers":_vm.headers,"items":_vm.accounts.items,"loading":_vm.loading,"no-data-text":(_vm.error) ? _vm.error : 'No accounts',"color":"primary","hide-default-footer":true,"options":_vm.options,"server-items-length":_vm.accounts.count},on:{"update:options":_vm.paginate},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-current-page":"","show-first-last-page":"","items-per-page-options":[5,10,50]},on:{"update:options":updateOptions}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('router-link',{attrs:{"to":item.type === 'user' ? { name: 'admin-profile', params: { username: item.name }} : { name: 'admin-organisation', params: { name: item.name }}}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:"item.storage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filesize")(item.storage,"", 0))+" ")]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("timediff")(item.received)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.received)))])])],1)]}},{key:"item.msg",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.msg.substring(0, 9)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.msg))])])],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{staticStyle:{"display":"inline-block","width":"120px"}},[(item.active)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("clear")])],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }