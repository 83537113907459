# Copyright (C) 2020 Lutra Consulting Limited. All rights reserved.
# Do not distribute without the express permission of the author.

<template>
  <v-progress-circular class="mx-2" :size="22" :width="3" indeterminate/>
</template>

<script>
export default {
  name: 'ProgressSpinner'
}
</script>

<style scoped>

</style>
