var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headerInvitations,"items":_vm.invitations,"no-data-text":"No invitations","hide-default-footer":"","hide-default-header":"","fixed-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[(header.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(header.tooltip)+" ")])]):_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],1)}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('b',[_vm._v(" "+_vm._s(item.org_name)+" ")])]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("remainingtime")(item.expire)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("datetime")(item.expire)))])])],1),_c('td',[(!_vm.expired(item.expire))?_c('div',{staticStyle:{"text-align":"end"}},[_c('v-chip',{staticClass:"white--text",attrs:{"elevation":"0","color":"green"},on:{"click":function($event){return _vm.acceptInvitation(item)}}},[_vm._v(" accept ")]),_c('v-chip',{staticClass:"white--text",attrs:{"elevation":"0","color":"red"},on:{"click":function($event){return _vm.removeInvitation(item)}}},[_vm._v(" reject ")])],1):_vm._e()])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }