var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"no-shrink column"},[_c('label',{staticClass:"mt-4 grey--text text--darken-1"},[_vm._v("Manage Access:")]),_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.displayedValues,"no-data-text":"No users","hide-default-header":"","hide-default-footer":_vm.displayedValues.length <= 10},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,style:(("width: " + (header.width) + "px"))},[(header.tooltip)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(header.text)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(header.tooltip)+" ")])]):_c('span',[_vm._v(" "+_vm._s(header.text)+" ")])],1)}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[(item.user.profile.first_name || item.user.profile.last_name)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('b',_vm._g({},on),[_vm._v(" "+_vm._s(item.user.username)+" ")])]}}],null,true)},[_c('span',[(item.user.profile.first_name)?_c('span',[_vm._v(_vm._s(item.user.profile.first_name))]):_vm._e(),(item.user.profile.last_name)?_c('span',[_vm._v(" "+_vm._s(item.user.profile.last_name))]):_vm._e()])]):_c('b',[_vm._v(" "+_vm._s(item.user.username)+" ")])],1),_c('td',[_c('v-select',{staticClass:"input-selection",staticStyle:{"width":"120px"},attrs:{"value":_vm.actualPermissions(item),"items":_vm.permissionStates,"disabled":_vm.project.creator === item.user.id || !_vm.project.access.owners.includes(_vm.app.user.id),"hide-details":"","label":"reader","single-line":""},on:{"input":function (e) { return _vm.valueChanged(item, e); }}})],1),_c('td',{staticClass:"justify-center px-0"},[_c('v-btn',{attrs:{"disabled":_vm.project.creator === item.user.id || !_vm.project.access.owners.includes(_vm.app.user.id),"icon":""},on:{"click":function($event){return _vm.removeUser(item.user)}}},[_c('v-icon',{attrs:{"color":"red darken-3"}},[_vm._v("delete")])],1)],1)])]}}])}),_c('label',{staticClass:"mt-4 grey--text text--darken-1"},[_vm._v("Invite collaborators:")]),_c('v-layout',{attrs:{"mx-3":"","my-2":"","align-end":""}},[_c('v-autocomplete',{attrs:{"placeholder":"Find user","loading":_vm.isLoading,"items":_vm.searchResults,"search-input":_vm.userQuery,"clearable":"","return-object":"","no-data-text":"Type more letters","hide-no-data":_vm.userQuery && _vm.userQuery.length > 2,"hide-details":""},on:{"update:searchInput":function($event){_vm.userQuery=$event},"update:search-input":function($event){_vm.userQuery=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{staticStyle:{"padding-bottom":"6px","padding-top":"6px"}},[_c('div',{staticClass:"v-list-item-content",domProps:{"innerHTML":_vm._s(((_vm.emphasizeMatch(item.value.username, _vm.userQuery)) + "  " + (_vm.getUserProfileName(item.value))))}})])]}}]),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c('v-btn',{staticClass:"ml-4 my-0",attrs:{"id":"invite-collaborator-btn","disabled":!_vm.user || !_vm.project.access.owners.includes(_vm.app.user.id)},on:{"click":_vm.addUser}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("add_circle")]),_vm._v(" Add ")],1)],1),_c('br'),_c('button',{ref:"hidden-input",staticStyle:{"visibility":"hidden"},attrs:{"id":"change-permissions-input"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }